<template>
  <div>
    <Row class="m-b-10" :gutter="8">
      <i-col :xs="4" :sm="4" :md="4" :lg="4" class="m-b-5">
        <Select
          size="small"
          v-model="processType"
          placeholder="类型"
          clearable
        >
        <Option v-for="item in types" :key="'type_'+item.value" :value="item.value">{{item.name}}</Option>
        </Select>
      </i-col>
      <i-col :xs="20" :sm="20" :md="20" :lg="20" class="m-b-5">
        <i-Switch v-model="enabled" true-color="#13ce66" false-color="#aeaeae" size="large">
          <span slot="open">所有</span>
          <span slot="close">有效</span>
        </i-Switch>
          <i-button
          type="success"
          size="small"
          class="m-l-10"
          @click="createFlow"
          v-if="detailId"
        >创建新流程</i-button>
      </i-col>
    </Row>
    <Table
       stripe border
       size="small"
      :loading="dataLoading"
      :data="list"
      :columns="dataColumns"
    ></Table>
  </div>
</template>

<script>
import { getProcessList } from '@/api/approval/process'
import { formatWorkflowStatus } from '@/utils/tagStatus'
import {
  getProcessTypeList
} from '@/api/approval/processconfig'

export default {
  data () {
    return {
      enabled: false,
      types: [],
      processType: null,
      dataLoading: false,
      list: [],
      dataColumns: [
        { title: '类型', align: 'center', key: 'name' },
        { title: '审批通道', align: 'center', key: 'subProcessTypeName' },
        { title: '描述', align: 'center', key: 'desc' },
        { title: '创建者', align: 'center', key: 'updateUserName' },
        { title: '更新时间', align: 'center', key: 'updateTime' },
        {
          title: '状态',
          align: 'center',
          render: (h, param) => {
            return formatWorkflowStatus(
              h,
              param.row.status,
              param.row.statusName
            )
          }
        },
        {
          title: '操作',
          align: 'center',
          render: (h, param) => {
            return h('div', [
              h('a', {
                style: { marginRight: '5px' },
                on: {
                  click: () => {
                    this.$store.commit('set_setting_pageType', 'setting')
                    this.$store.commit('set_setting_sub', param.row.subProcessType)
                    this.$store.commit('set_setting_type', param.row.type)
                    this.$store.commit('set_setting_detailId', param.row.id)
                    this.$store.commit('set_setting_name', param.row.typeName)
                    this.$store.commit('set_setting_subName', param.row.subProcessTypeName)
                  }
                }
              }, '设置')
              // h('a', {
              //   style: { marginRight: '5px' },
              //   on: {
              //     click: () => {
              //       this.$store.commit('set_setting_pageType', 'setting')
              //       this.$store.commit('set_setting_detailId', param.row.id)
              //       this.$store.commit('set_setting_name', param.row.name)
              //     }
              //   }
              // }, '设置')
            ])
          }
        }
      ]
    }
  },
  methods: {
    createFlow () {
      this.$store.commit('set_setting_pageType', 'default')
      this.$store.commit('set_setting_detailId', null)
      this.$store.commit('set_setting_name', null)
      this.$store.commit('set_setting_subName', null)
    },
    getTypes () {
      getProcessTypeList({ companyId: this.companyId }).then((data) => {
        if (data && data.length > 0) {
          this.types = data
        } else {
          this.types = []
        }
        // this.initSubProcessList()
      })
    },
    initData () {
      this.dataLoading = true
      const postData = {
        companyId: this.companyId,
        processType: this.processType,
        status: this.enabled ? null : 1
      }
      getProcessList(postData)
        .then((res) => {
          this.dataLoading = false
          if (res && !res.errcode) {
            this.list = res
          } else {
            this.list = []
            this.$Notice.error({
              title: '获取审批流程配置出错',
              desc: res.errmsg
            })
          }
        })
        .catch(() => {
          this.dataLoading = false
        })
    }
  },
  created () {
    this.getTypes()
    this.initData()
  },
  computed: {
    detailId () {
      return this.$store.state.setting.detailId
    },
    beginUpdate () {
      return this.$store.state.setting.beginUpdate
    },
    companyId () {
      return this.$store.state.setting.companyId
    }
  },
  watch: {
    enabled (val) {
      this.initData()
    },
    processType (val) {
      this.initData()
    },
    beginUpdate () {
      this.initData()
    },
    companyId (val) {
    }
  }
}
</script>
