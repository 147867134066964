import request from '@/utils/requestV2'
const qs = require('qs')

// 初始化一个新的审批流程【已测】
export function addProcess (data) {
  return request({
    url: '/ooh-approval/v1/process/addprocess',
    method: 'post',
    data: qs.stringify(data)
  })
}
// 初始化一个新的审批流程【已测】
export function addProcessWithNodes (data) {
  return request({
    url: '/ooh-approval/v1/process/addprocesswithnodes',
    method: 'post',
    data: qs.stringify(data)
  })
}
// 设置指定审批流程生效【已测】
export function enableProcess (data) {
  return request({
    url: '/ooh-approval/v1/process/enableProcess',
    method: 'post',
    data: qs.stringify(data)
  })
}
// 获取公司配置的全部审批流程【已测】
export function getProcessList (data) {
  return request({
    url: '/ooh-approval/v1/process/getprocesslist',
    method: 'post',
    data: qs.stringify(data)
  })
}
